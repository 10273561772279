<template>
  <div class="VacancyList">
    <h2 class="VacancyList-Title Wrapper Title Title--h2 Title--h2-small">новейший <br>список вакансий</h2>

    <div class="VacancyList-SliderWrapper">
      <swiper
        class="VacancyList-Slider"
        :options="options"
      >
        <swiper-slide
          class="VacancyList-SliderSlide"
          v-for="(vacancy, i) in this.$t('vacancy')"
          :key="i"
        >
          <VacancyCard :vacancy="vacancy"/>
        </swiper-slide>
      </swiper>    
    </div>
  </div>
</template>

<script>
import VacancyCard from './VacancyCard'

export default {
  name: 'VacancyList',
  components: {
    VacancyCard
  },
  data() {
    return {
      options: {
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
          960: {
            slidesPerView: 2.6,
            spaceBetween: 32,
          },
          1248: {
            slidesPerView: 2.6,
            spaceBetween: 62,
          }
        }
      }
    }
  }  
}
</script>

<style lang="scss">
  .VacancyList {
    &-Title {
      margin-bottom: 20px;

      @media (min-width: $screen-l) {
        margin-bottom: 40px;
      }
    }

    &-SliderWrapper {
      overflow: hidden;
    }

    &-Slider {
      max-width: 320px;
      width: 100%;
      padding: 0 18px;
      margin: 0 auto;
      position: relative;
      cursor: ew-resize;

      @media (min-width: $screen-l) {
        max-width: 960px;
        padding: 0 15px;
      }

      @media (min-width: $screen-xl) {
        max-width: 1262px;
      }
    }

    &-SliderSlide {
      height: auto;
      box-sizing: border-box;
    }   
  }
</style>
