<template>
  <div class="WorkPlay">
    <h2 class="Wrapper WorkPlay-Title Title Title--h2 Title--h2-small">Work Hard — Play&nbsp;Hard</h2>
    <div class="WorkPlay-SliderWrapper" ref="SliderWrapper">
      <swiper
        class="WorkPlay-Slider"
        :options="sliderPhotoOptions"
        ref="slider"
      >
        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-1.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide WorkPlay-SliderSlide--wide">
          <img src="@/assets/images/workplay-2.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-3.jpg" alt="photo">
          <img src="@/assets/images/workplay-4.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-5.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide WorkPlay-SliderSlide--wide">
          <img src="@/assets/images/workplay-6.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-7.jpg" alt="photo">
          <img src="@/assets/images/workplay-8.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-9.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-11.jpg" alt="photo">
          <img src="@/assets/images/workplay-12.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide WorkPlay-SliderSlide--wide">
          <img src="@/assets/images/workplay-13.jpg" alt="photo">
        </swiper-slide>

        <swiper-slide class="WorkPlay-SliderSlide">
          <img src="@/assets/images/workplay-15.jpg" alt="photo">
          <img src="@/assets/images/workplay-16.jpg" alt="photo">
        </swiper-slide>        
      </swiper>
    </div>

    <div class="Wrapper">
      <div class="WorkPlay-Text">
        <p class="WorkPlay-TextIn Text">Мы трудоголики, работаем 24/7, но когда приходит время отдыхать, мы отрываемся по полной «со своими людьми».</p>
      </div>

      <div class="WorkPlay-Video">
        <swiper
          class="swiper WorkPlay-VideoSlider"
          :options="sliderVideoOptions"
        >
          <swiper-slide
            class="WorkPlay-VideoSliderSlide"
            v-for="(video, i) in videos"
            :key="i"
          >
            <div
              class="VideoCard"
              @click="openVideo('video', video.url)"
            >
              <div class="VideoCard-Caption">
                {{ video.caption }}
              </div>
              <div class="VideoCard-Image">
                <img
                  :src="require(`@/assets/images/${video.img}`)"
                  alt="photo"
                >
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="WorkPlay-VideoSliderPagination"></div>
        <div class="WorkPlay-VideoSliderArrow WorkPlay-VideoSliderArrow--prev"></div>
        <div class="WorkPlay-VideoSliderArrow WorkPlay-VideoSliderArrow--next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper'
import BaseModal from "@/components/BaseModal";

export default {
  name: 'WorkPlay',
  data() {
    return {
      videos: [
        {
          caption: 'Новый год Syndicate 2022',
          url: 'https://www.youtube.com/watch?v=Vf-pBW4n3no',
          img: 'workplay-video-1.jpg'
        },
        {
          caption: 'Syndicate Group & Kinza 360',
          url: 'https://www.youtube.com/watch?v=EU2ux4Fp-ro',
          img: 'workplay-video-2.jpg'
        }
      ],
      sliderPhotoOptions: {
        modules: [Autoplay],
        slidesPerView: 'auto',
        spaceBetween: 12,
        loop: true,
        loopAdditionalSlides: 5,
        speed: 5000,
        autoplay: {
          delay: 0,
          enabled: true
        },
        breakpoints: {
          960: {
            spaceBetween: 12
          },
          1248: {
            spaceBetween: 24
          }
        }
      },
      sliderVideoOptions: {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          prevEl: '.WorkPlay-VideoSliderArrow--prev',
          nextEl: '.WorkPlay-VideoSliderArrow--next'
        },
        pagination: {
          el: '.WorkPlay-VideoSliderPagination',
          clickable: true
        }
      }
    }
  },
  methods: {
    openVideo(content, videoUrl) {
      this.$modal.show(
          BaseModal,
          { content, videoUrl },
          {
            name: 'Modal',
            width: 768,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            clickToClose: false
          }
      );
    }
  },
}
</script>

<style lang="scss">
.WorkPlay {
  padding-bottom: 24px;

  &-Title {
    margin-bottom: 30px;

    @media (min-width: $screen-l) {
      margin-bottom: 40px;
    }
  }

  &-SliderWrapper {
    overflow: hidden;
  }

  &-Slider {
    cursor: ew-resize;

    .swiper-wrapper {
      transition-timing-function: linear;
    }
  }

  &-SliderSlide {
    width: 280px;

    &--wide {
      width: 608px;
    }

    img {
      display: block;

      + img {
        margin-top: 12px;

        @media (min-width: $screen-l) {
          margin-top: 24px;
        }
      }
    }
  }

  &-Text {
    padding-top: 40px;
    padding-bottom: 22px;

    @media (min-width: $screen-l) {
      padding-top: 180px;
      padding-bottom: 180px;      
      background-image: url('~@/assets/images/workplay-icons.svg');
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 36% auto;
    }
  }

  &-TextIn {
    text-align: center;

    @media (min-width: $screen-l) {
      max-width: 593px;
      margin-left: auto;
      text-align: left;
    }
  }

  &-Video {
    position: relative;
    max-width: 816px;
    margin-right: auto;
    margin-left: auto;
  }

  &-VideoSliderArrow {
    display: none;

    @media (min-width: $screen-l) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      display: block;
      width: 41px;
      height: 38px;
      background-image: url('~@/assets/images/green-arrow.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      cursor: pointer;

      &--next {
        left: 100%;
        margin-left: 20px;
  
        @media (min-width: $screen-xl) {
          margin-left: 60px;
        }
      }
  
      &--prev {
        right: 100%;
        margin-right: 20px;
        transform: translateY(-50%) rotate(180deg);
  
        @media (min-width: $screen-xl) {
          margin-right: 60px;
        }
      }
  
      &.swiper-button-disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  &-VideoSliderPagination {
    &.swiper-pagination-horizontal {
      position: absolute;
      top: 100%;
      bottom: auto;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 12px;
      text-align: center;

      @media (min-width: $screen-l) {
        padding-top: 14px;
      }
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      transition: width 0.25s ease-in-out;
      background-color: var(--color-text-main);
      opacity: 0.6;

      &.swiper-pagination-bullet-active {
        opacity: 1;
        width: 24px;
        background-color: var(--color-text-main2);
      }
    }
  }
}
</style>
