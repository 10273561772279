<template>
  <div class="VacancyCard">
    <h3 class="VacancyCard-Name">
      <router-link
        :to="{ name: 'Vacancy', params: {id: vacancy.id} }"
      >
        {{ vacancy.name }}
      </router-link>
    </h3>

    <div class="VacancyCard-Region VacancyRegion">
      {{ vacancy.region }}
    </div>
      
    <div
      class="VacancyCard-Features VacancyFeatures"
      v-if="vacancy.features"
    >
      <span
        class="VacancyFeatures-Item"
        v-for="(feature, i) in vacancy.features"
        :key="i"
      >
        {{ feature }}
      </span>
    </div>

    <div class="VacancyCard-Description VacancyDescription">
      <ul
        v-if="vacancy.skills"
      >
        <li
          v-for="(skill, i) in vacancy.skills"
          :key="i"
        >
          {{ skill }}
        </li>
      </ul>
    </div>

    <div class="VacancyCard-More">
      <router-link
        class="VacancyCard-MoreLink"
        :to="{ name: 'Vacancy', params: { id: vacancy.id } }"
      >
        Узнать больше
      </router-link>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'VacancyCard',
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .VacancyCard {
    display: flex;
    flex-direction: column;
    padding: 35px 18px;
    border: 4px solid var(--color-border);

    @media (min-width: $screen-xl) {
      padding: 50px 42px;
    }

    .swiper-slide > & {
      height: 100%;
    }

    &-Name {
      margin-bottom: 26px;
      color: var(--color-text-main2);
      font-size: 20px;
      font-weight: 700;
      line-height: 1.15;
      text-transform: uppercase;
      text-align: center;
      
      @media (min-width: $screen-l) {
        margin-bottom: 29px;
        font-size: 29px;
        line-height: 1.31;
        text-align: left;
      }
    }

    &-Region {
      margin-bottom: 26px;
      justify-content: center;

      @media (min-width: $screen-l) {
        margin-bottom: 30px;
        justify-content: flex-start;
      }
    }

    &-Features {
      margin-bottom: 26px;
      justify-content: center;

      @media (min-width: $screen-l) {
        margin-bottom: 32px;
        justify-content: flex-start;
      }
    }

    &-Description {
      margin-bottom: 35px;

      @media (min-width: $screen-l) {
        margin-bottom: 50px;
      }
    }

    &-More {
      margin-top: auto;
      text-align: center;

      @media (min-width: $screen-l) {
        text-align: left;
      }
    }

    &-MoreLink {
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      text-transform: uppercase;

      @media (min-width: $screen-l) {
        font-size: 16px;
      }
      
      &::after {
        content: '';
        width: 14px;
        height: 13px;
        margin-left: 10px;
        background-image: url('~@/assets/images/green-arrow.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;

        @media (min-width: $screen-l) {
          width: 22px;
          height: 20px;
        }
      }
    }
  }
</style>
