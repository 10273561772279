<template>
  <div class="CareerSteps Wrapper">
    <h2 class="CareerSteps-Title Title Title--h2 Title--h2-small">на шаг ближе <br>к работе мечты</h2>

    <ol class="CareerSteps-List">
      <li
        class="CareerSteps-ListItem"
        v-for="(step, i) in steps"
        :key="i"
      >
        <span class="CareerSteps-ListItemCounter">{{ ((i + 1).toString()).length === 1 ? `0${(i + 1)}` : (i + 1) }}</span>
        <span class="CareerSteps-ListItemText Text">{{ step }}</span>
      </li>
    </ol>
  </div>

</template>

<script>
export default {
  name: 'CareerSteps',
  data() {
    return {
      steps: [
        'Сильная фарм команда, которая состоит из 20 человек во главе с руководителем отдела.',
        'Одна из лучших самописных платформ для удобного анализа и оптимизации трафика.',
        'Команда дизайнеров из 30 человек, которые круглосуточно делают конвертящие креативы.',
        'Безлимитная оборотка и налаженный алгоритм по выдаче бурж карт для разных источников трафика.',
        'Команда по разработке iOS и Android приложений, состоящая из 15 человек.',
        'Более 30 приложений всегда в онлайне на двух платформах.',
        'За 5 лет работы Syndicate Group накопил большой опыт и выстроил связи со всеми основными брендами igaming рынка.',
        'Отдел интеграции, справится с самыми сложными задачами по настройке клоак и любому другому необходимому подключению.'
      ]
    }
  }
}
</script>

<style lang="scss">
.CareerSteps {
  &-Title {
    margin-bottom: 20px;

    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }

  &-List {
    padding: 0;
    margin: 0;

    @media (min-width: $screen-l) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 45px 40px;
    }

    @media (min-width: $screen-xl) {
      gap: 90px 80px;
    }
  }

  &-ListItem {
    display: flex;
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 0;
    }
  }

  &-ListItemCounter {
    display: block;
    margin-right: 12px;
    color: var(--color-text-main2);
    font-size: 42px;
    font-weight: 700;
    line-height: 1.12;

    @media (min-width: $screen-l) {
      margin-right: 0;
      margin-bottom: 12px;
      font-size: 80px;
    }
  }

  &-ListItemText {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;

    @media (min-width: $screen-l) {
      font-size: 16px;
    }
  }
}
</style>
